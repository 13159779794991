export const SOLANA_NETWORK = process.env.NEXT_PUBLIC_SOLANA_NETWORK || 'mainnet'
export const SERVER_URL = process.env.NEXT_PUBLIC_SERVER_URL || 'https://api.thestonedfrogs.io'

export const CLIENT_ID = "39a1fdd9-a47b-4235-bfaf-ff0054dfa697";
export const FRONT_END_URL = "https://homiepad.bunniesnhomies.io";

export const CLIENT_NAME = "Bunnies N Homies";
export const PROJECT_NAME = "Homiepad";
export const TWITTER_USERNAME = "BunniesNHomies";
export const GTAG_ID = "G-035SK1XBD1";

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCHMdrZqomGxGsLQBuYIfUGeHOy180yUaI",
  authDomain: "bunnies-n-homies.firebaseapp.com",
  projectId: "bunnies-n-homies",
  storageBucket: "bunnies-n-homies.appspot.com",
  messagingSenderId: "55948594201",
  appId: "1:55948594201:web:c0081b0a6639815ac22e88",
  measurementId: "G-035SK1XBD1"
}

export const COLORS = {
  light: {
    primary: "#ff7600",
    secondary: "white",
    background: '#111827'
  },
};

export const S3_FOLDER_PATH =
  "https://s3.us-west-1.amazonaws.com/cdn.thestonedfrogs.io/_assets/bunnies_n_homies/";

export const EXTRA_MENU = [
  {
    name: "Staking",
    href: "https://bunniesnhomies-staking.vercel.app",
    target: "_blank",
  },
  // {
  //   name: "Flex2Earn",
  //   href: "https://bunnies-n-homies.flex-n-earn.com/",
  //   target: "_blank",
  // },
];
